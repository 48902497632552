import React from 'react';
import '../styles/Footer.css';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaBuilding, FaWhatsapp } from 'react-icons/fa';

function Footer() {
  return (
    <footer id="contact" className="footer">
      <div className="footer-content">
        <h2>צור קשר</h2>
        <div className="contact-info">
          <div className="contact-item">
            <FaBuilding className="icon" />
            <p>ניקיוניק בע"מ ח.פ. 517006656</p>
          </div>
          <div className="contact-item">
            <FaEnvelope className="icon" />
            <p>אימייל: office@nikayonic.com</p>
          </div>
          <div className="contact-item">
            <FaPhone className="icon" />
            <p>טלפון: 058-7895445</p>
          </div>
          <div className="contact-item">
            <FaWhatsapp className="icon" />
            <p>ווצאפ: 050-2803465</p>
          </div>
          <div className="contact-item">
            <FaMapMarkerAlt className="icon" />
            <p>כתובת: רחוב פלי"ם 2, בניין אשול 1, חיפה</p>
          </div>
        </div>
        <div className="map-container">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3350.204907409011!2d35.0019571!3d32.8149433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151dbb9bf9d07c9d%3A0x90a68d54a42c9452!2sRegus%20Pal-Yam%20%D7%A8%D7%99%D7%92'%D7%A1%20%D7%A4%D7%9C-%D7%99%D7%9D%20%D7%97%D7%99%D7%A4%D7%94!5e0!3m2!1siw!2sil!4v1692609672342!5m2!1siw!2sil"
            width="300"
            height="200"
            allowFullScreen=""
            loading="lazy"
            title="Google Maps"
        ></iframe>
        </div>
      </div>
      <div className="footer-bottom">
        <p>כל הזכויות שמורות לניקיוניק © 2024</p>
      </div>
    </footer>
  );
}

export default Footer;
