// src/components/HomePage.js
import React from 'react';
import Services from '../components/Services';
import QuoteForm from '../components/QuoteForm';
import FAQ from '../components/FAQ';
import Footer from '../components/Footer';

function HomePage({ languageData }) {
  return (
    <div>
      <div className="bubbles"> {/* הוסף את אלמנט הבועות כאן */}
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <Services languageData={languageData} />
      <QuoteForm languageData={languageData} />
      <FAQ languageData={languageData} />
      <Footer languageData={languageData} />
    </div>
  );
}

export default HomePage;
