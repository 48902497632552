import React, { useState, useEffect } from 'react';
import ServiceModal from './ServiceModal';
import '../styles/Services.css';

function Services({ languageData }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 100);
    return () => clearTimeout(timer);
  }, []);

  const services = languageData.hero.services;

  const openModal = (service) => {
    setSelectedService(service);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedService(null);
  };

  return (
    <section id="services" className="services">
      <h2 className={isVisible ? 'fade-in-top visible' : 'fade-in-top'}>
        {languageData.hero.Availableservices}
      </h2>
      <div className={`services-list ${isVisible ? 'fade-in visible' : 'fade-in'}`}>
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <h3>{service.name}</h3>
            <p>{service.description}</p>
            <button onClick={() => openModal(service)}>{languageData.hero.LearnMore}</button>
          </div>
        ))}
      </div>
      {selectedService && (
        <ServiceModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          service={selectedService}
          languageData={languageData}
        />
      )}
    </section>
  );
}

export default Services;