// src/components/FAQ.js
import React, { useEffect, useRef, useState } from 'react';
import '../styles/FAQ.css';

function FAQ({ languageData }) {
  const [visibleItems, setVisibleItems] = useState([]);
  const faqRef = useRef(null);
  
  const faqs = [
    { question: languageData.faq.question1, answer: languageData.faq.answer1 },
    { question: languageData.faq.question2, answer: languageData.faq.answer2 },
    { question: languageData.faq.question3, answer: languageData.faq.answer3 },
    { question: languageData.faq.question4, answer: languageData.faq.answer4 },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            let timer = setInterval(() => {
              setVisibleItems((prevItems) => {
                if (prevItems.length < faqs.length) {
                  return [...prevItems, prevItems.length];
                } else {
                  clearInterval(timer);
                  return prevItems;
                }
              });
            }, 200); // הוסף שאלה חדשה כל 200 מילישניות

            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 } // התחל את האנימציה כאשר 10% מהאלמנט נראה
    );

    if (faqRef.current) {
      observer.observe(faqRef.current);
    }

    return () => {
      if (faqRef.current) {
        observer.unobserve(faqRef.current);
      }
    };
  }, [faqs.length]);


  return (
    <section id="faq" className="faq" ref={faqRef}>
      <h2 className='faqTitel'>{languageData.faq.title}</h2>
      {faqs.map((faq, index) => (
        <div 
          key={index} 
          className={`faq-item ${visibleItems.includes(index) ? 'visible' : ''}`}
        >
          <h3>{faq.question}</h3>
          <p>{faq.answer}</p>
        </div>
      ))}
    </section>
  );
}

export default FAQ;