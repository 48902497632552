import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Header from './components/Header';
import HomePage from './Pages/HomePage';
import AccessibilityOptions from './components/AccessibilityOptions';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import languages from './language/languages.json';

function App() {
  const [language, setLanguage] = useState('he');
  const [languageData, setLanguageData] = useState(languages[language]);

  useEffect(() => {
    setLanguageData(languages[language]);
    // עדכון כיוון המסמך
    document.body.dir = language === 'he' ? 'rtl' : 'ltr';
    document.body.style.textAlign = language === 'he' ? 'right' : 'left';
  }, [language]);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  return (
    <Router>
      <div className={`App ${language !== 'he' ? 'ltr-layout' : ''}`}>
        <Header 
          languageData={languageData} 
          onLanguageChange={handleLanguageChange}
          currentLanguage={language}
        />
        <Routes>
          <Route path="/" element={<HomePage languageData={languageData} currentLanguage={language} />} />
        </Routes>
        <AccessibilityOptions languageData={languageData} currentLanguage={language} />
        <ToastContainer 
          position={language === 'he' ? "top-right" : "top-left"}
          rtl={language === 'he'}
        />
      </div>
    </Router>
  );
}

export default App;