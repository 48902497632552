import React from 'react';
import Flag from 'react-world-flags';
import '../styles/LanguageSwitcher.css';
import PropTypes from 'prop-types';

function LanguageSwitcher({ onLanguageChange }) {

  const handleClick = (lang) => {
    if (typeof onLanguageChange === 'function') {
      onLanguageChange(lang);
    } else {
      console.error("onLanguageChange is not a function");
    }
  };

  return (
    <div className="language-switcher">
      <Flag code="FR" height="24" onClick={() => handleClick('fr')} />
      <Flag code="US" height="24" onClick={() => handleClick('en')} />
      <Flag code="IL" height="24" onClick={() => handleClick('he')} />
      <Flag code="RU" height="24" onClick={() => handleClick('ru')} />
      <Flag code="UA" height="24" onClick={() => handleClick('uk')} />
    </div>
  );
}

LanguageSwitcher.propTypes = {
  onLanguageChange: PropTypes.func.isRequired,
};

export default LanguageSwitcher;