import React, { useState, useEffect } from 'react';
import '../styles/Header.css';
import logo from '../assets/logo.png';
import LanguageSwitcher from './LanguageSwitcher';
import SmoothScroll from 'smooth-scroll';

function Header({ languageData, onLanguageChange }) {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    new SmoothScroll('a[href*="#"]', {
      speed: 800,
      speedAsDuration: true,
      offset: 0
    });
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="header">
      <LanguageSwitcher onLanguageChange={onLanguageChange} />
      <img src={logo} alt="NIKAYONIC Logo" className="logo" />
      <nav>
        <ul className={`nav-links ${isMobileMenuOpen ? 'open' : ''}`}>
          <li><a href="#services">{languageData.header.services}</a></li>
          <li><a href="#faq">{languageData.header.faq}</a></li>
          <li><a href="#contact">{languageData.header.contact}</a></li>
        </ul>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          ☰
        </div>
      </nav>
    </header>
  );
}

export default Header;
