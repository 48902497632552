import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Howl } from 'howler';
import emailjs from '@emailjs/browser';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Modal.css';

const Modal = ({ isOpen, onClose, onSubmit, selectedDetails }) => {
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [successSound, setSuccessSound] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSuccessSound(new Howl({
      src: ['/success.mp3']
    }));
    
    // Initialize EmailJS with your public key
    emailjs.init("gfxDp4TCd_4mw5rBs");
  }, []);

  const sendOtp = useCallback(async (phoneNumber) => {
    setIsLoading(true);
    try {
      const response = await axios.post('http://www.nikayonic.com:3001/send-otp', {
        phoneNumber: '+972' + phoneNumber.substring(1)
      });

      if (response.data.success) {
        toast.success("קוד OTP נשלח בהצלחה!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setStep(2);
      } else {
        throw new Error(response.data.error || "שליחת קוד OTP נכשלה");
      }
    } catch (error) {
      console.error("Error sending OTP:", error.response ? error.response.data : error.message);
      toast.error(`שליחת קוד OTP נכשלה: ${error.response ? error.response.data.error : error.message}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handlePhoneSubmit = useCallback(() => {
    if (phone.length === 10 && phone.startsWith('05')) {
      sendOtp(phone);
      console.log('Phone submitted:', phone);
    } else {
      toast.error("הכנס מספר טלפון נייד תקין המתחיל ב-'05'", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [phone, sendOtp]);

  const handlePhoneChange = useCallback((e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 10) {
      setPhone(value);
    }
  }, []);

  const handleOtpChange = useCallback((element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling && element.value !== '') {
      element.nextSibling.focus();
    }
  }, [otp]);

  const handleOtpSubmit = useCallback(async () => {
    const otpValue = otp.join('');
    if (otpValue.length === 6) {
      setIsLoading(true);
      try {
        const response = await axios.post('http://www.nikayonic.com:3001/verify-otp', {
          phoneNumber: '+972' + phone.substring(1),
          otp: otpValue
        });
  
        if (response.data.success) {
          toast.success("הפרטים אומתו בהצלחה!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          onSubmit();
          if (successSound) {
            successSound.play();
          }
          
          // שליחת מייל לאחר אימות מוצלח
          const emailContent = `
            מספר טלפון: ${phone}
            שירותים נבחרים: ${selectedDetails.selectedServices.map(s => s.name).join(', ')}
            אזור: ${selectedDetails.area}
            סוג שירות: ${selectedDetails.serviceType}
            תדירות: ${selectedDetails.frequency}
            מספר חדרי אמבטיה: ${selectedDetails.bathroomCount}
            מחיר משוער: ${selectedDetails.estimatedPrice} ₪
            זמן משוער: ${selectedDetails.estimatedTime} שעות
          `;
  
          try {
            await emailjs.send(
              'service_ccg88id',
              'template_vstkj4p',
              {
                to_email: 'mototommietal.com@gmail.com',
                subject: 'פרטי הזמנה חדשה',
                message: emailContent
              }
            );
            console.log('Email sent successfully');
          } catch (emailError) {
            console.error('Failed to send email:', emailError);
            toast.error('נכשל בשליחת האימייל, אנא נסה שוב מאוחר יותר', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
  
          setTimeout(() => {
            onClose();
          }, 2000);
        } else {
          throw new Error("אימות קוד OTP נכשל");
        }
      } catch (error) {
        console.error("Error verifying OTP:", error.response ? error.response.data : error.message);
        toast.error(`אימות קוד OTP נכשל: ${error.response ? error.response.data.error : error.message}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error("אנא הכנס קוד OTP מלא", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [otp, phone, onSubmit, onClose, successSound, selectedDetails]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <button className="modal-close" onClick={onClose}>×</button>
        {step === 1 && (
          <div>
            <h2>הכנס מספר טלפון</h2>
            <input
              type="tel"
              value={phone}
              onChange={handlePhoneChange}
              placeholder="05xxxxxxxx"
              className="phone-input"
              dir="ltr"
            />
            <button onClick={handlePhoneSubmit} className="modal-button" disabled={isLoading}>
              {isLoading ? <div className="loader"></div> : 'שלח קוד OTP'}
            </button>
          </div>
        )}
        {step === 2 && (
          <div>
            <h2>הכנס קוד OTP</h2>
            <div className="otp-container">
              {otp.map((data, index) => {
                return (
                  <input
                    className="otp-input"
                    type="text"
                    name="otp"
                    maxLength="1"
                    key={index}
                    value={data}
                    onChange={e => handleOtpChange(e.target, index)}
                    onFocus={e => e.target.select()}
                    dir="ltr"
                  />
                )
              })}
            </div>
            <button onClick={handleOtpSubmit} className="modal-button" disabled={isLoading}>
              {isLoading ? <div className="loader"></div> : 'אמת קוד OTP'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;