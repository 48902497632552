import React from 'react';
import Modal from 'react-modal';
import '../styles/ServiceModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

Modal.setAppElement('#root'); // Ensure accessibility

function ServiceModal({ isOpen, onRequestClose, service, languageData, currentLanguage }) {
  const isRTL = currentLanguage === 'he'; // Check if the current language is Hebrew

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={languageData.hero.ServiceDetails || "Service Details"}
      className={`Modal ${isRTL ? 'rtl' : 'ltr'}`}
      overlayClassName={`Overlay ${isRTL ? 'rtl' : 'ltr'}`}
      closeTimeoutMS={500}
    >
      <div className={`ModalContainer ${isRTL ? 'rtl' : 'ltr'}`}>
        <button onClick={onRequestClose} className="close-button">
          {languageData.hero.Close || "X"}
        </button>
        <div className={`ModalContent ${isRTL ? 'rtl' : 'ltr'}`}>
          <h2>{service.name}</h2>
          <p>{service.description}</p>
          {service.subCategories && service.subCategories.map((subCategory, index) => (
            <div key={index}>
              <h3>{subCategory.title}</h3>
              <div className="details-container">
                {subCategory.description.map((detail, i) => (
                  <div key={i} className="detail-item">
                    <FontAwesomeIcon icon={faCheckCircle} className="detailIcon" /> {detail}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default ServiceModal;