import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWheelchair } from '@fortawesome/free-solid-svg-icons';
import '../styles/AccessibilityOptions.css';

function AccessibilityOptions({ languageData }) {
  const [isOpen, setIsOpen] = useState(false);
  const [fontSize, setFontSize] = useState(16); // ברירת מחדל לגודל הטקסט
  const [fontSizeClicks, setFontSizeClicks] = useState(0); // מונה לחיצות על כפתור הגדלת הטקסט
  const [showInstallMessage, setShowInstallMessage] = useState(false);

  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  const increaseFontSize = () => {
    if (fontSizeClicks < 4) {
      setFontSize((prevSize) => prevSize + 2);
      setFontSizeClicks((prevClicks) => prevClicks + 1);
      document.body.style.fontSize = `${fontSize + 2}px`;
    }
  };

  const decreaseFontSize = () => {
    if (fontSizeClicks > 0) {
      setFontSize((prevSize) => Math.max(10, prevSize - 2));
      setFontSizeClicks((prevClicks) => prevClicks - 1);
      document.body.style.fontSize = `${fontSize - 2}px`;
    }
  };

  const resetFontSize = () => {
    setFontSize(16);
    setFontSizeClicks(0);
    document.body.style.fontSize = '';
  };

  const enableTextToSpeech = () => {
    const speechSynthesis = window.speechSynthesis;
    const voices = speechSynthesis.getVoices();
    const hebrewVoice = voices.find((voice) => voice.lang === 'he-IL');

    if (hebrewVoice) {
      const utterance = new SpeechSynthesisUtterance(document.body.innerText);
      utterance.voice = hebrewVoice;
      utterance.lang = 'he-IL';
      utterance.rate = 1; // קצב דיבור רגיל
      utterance.pitch = 1; // גובה קול רגיל
      speechSynthesis.speak(utterance);
    } else {
      setShowInstallMessage(true);
    }
  };

  const openInstallInstructions = () => {
    alert(
      'כדי להתקין קול בעברית במערכת שלך, נא לבצע את הפעולות הבאות:\n\n' +
      'Windows:\n' +
      '1. עבור אל "הגדרות" > "זמן ושפה" > "שפה" > "הוסף שפה"\n' +
      '2. בחר "עברית" והתקן את חבילת השפה.\n' +
      '3. אם השפה כבר מותקנת, לחץ על השפה ובחר "אפשרויות" > "הורד Text-to-Speech".\n\n' +
      'Mac:\n' +
      '1. עבור אל "העדפות מערכת" > "דיבור"\n' +
      '2. הוסף קול בעברית מרשימת הקולות.\n' +
      '3. אם השפה כבר מותקנת, לחץ על השפה ובחר להוריד את האפשרות של Text-to-Speech.'
    );
  };

  const enableKeyboardNavigation = () => {
    const focusableElements = document.querySelectorAll('a, button, input, textarea');
    let currentIndex = 0;

    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
        currentIndex = (currentIndex + 1) % focusableElements.length;
        focusableElements[currentIndex].focus();
      } else if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
        currentIndex = (currentIndex - 1 + focusableElements.length) % focusableElements.length;
        focusableElements[currentIndex].focus();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  };

  useEffect(() => {
    if (isOpen) {
      enableKeyboardNavigation();
    }
  }, [isOpen]);

  return (
    <div className="accessibility-container">
      <button className="accessibility-button" onClick={toggleOptions}>
        <FontAwesomeIcon icon={faWheelchair} size="2x" />
      </button>
      {isOpen && (
        <div className="accessibility-options">
          <button className="close-button" onClick={toggleOptions}>X</button>
          <h3>Options</h3>
          <div className="button-group">
            <button onClick={increaseFontSize}>+</button>
            <button onClick={decreaseFontSize}>-</button>
            <button onClick={resetFontSize}>{languageData.Accessibility.ResetTextSize}</button>
            <button onClick={() => document.body.style.filter = 'invert(100%)'}>{languageData.Accessibility.HighContrast}</button>
            <button onClick={() => document.body.style.filter = ''}>{languageData.Accessibility.ResetContrast}</button>
            <button onClick={enableTextToSpeech}>{languageData.Accessibility.TextToSpeech}</button>
          </div>
          {showInstallMessage && (
            <div className="install-message">
              <p>קול בעברית אינו זמין במערכת שלך.</p>
              <button onClick={openInstallInstructions}>כיצד להתקין קול בעברית</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AccessibilityOptions;
