import React, { useState, useEffect, useCallback, useMemo } from 'react';
import '../styles/QuoteForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faSoap, faSprayCan, faTrash, faShirt, faSink } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal';

function QuoteForm({ languageData }) {
  
  const services = [
    { name: languageData.hero.serviceAddition.serv1, icon: faKey, time: 60, price: 50 },
    { name: languageData.hero.serviceAddition.serv2, icon: faShirt, time: 60, price: 50 },
    { name: languageData.hero.serviceAddition.serv3, icon: faSink, time: 60, price: 30 },
    { name: languageData.hero.serviceAddition.serv4, icon: faTrash, time: 30, price: 20 },
  ];

  const {
    Comprehensivecleaning,
    Cleaningandhousekeeping,
    Upto70squaremeters,
    "70to100squaremeters": t70to100,
    "100to180squaremeters": t100to180,
    Above180squaremeters,
    onesinamonth,
    morethanonesinamonth,
    Lessthan4timesamonth,
    "4timesamonthormore": t4timesamonthormore
  } = languageData.hero;

  const priceMatrix = useMemo(() => ({
    [Comprehensivecleaning]: {
      [Upto70squaremeters]: { [morethanonesinamonth]: 550, [onesinamonth]: 600 },
      [t70to100]: { [morethanonesinamonth]: 600, [onesinamonth]: 650 },
      [t100to180]: { [morethanonesinamonth]: 650, [onesinamonth]: 700 },
      [Above180squaremeters]: { [morethanonesinamonth]: 850, [onesinamonth]: 900 },
    },
    [Cleaningandhousekeeping]: {
      [Upto70squaremeters]: { [t4timesamonthormore]: 400, [Lessthan4timesamonth]: 450 },
      [t70to100]: { [t4timesamonthormore]: 450, [Lessthan4timesamonth]: 500 },
      [t100to180]: { [t4timesamonthormore]: 550, [Lessthan4timesamonth]: 600 },
      [Above180squaremeters]: { [t4timesamonthormore]: 800, [Lessthan4timesamonth]: 900 },
    },
  }), [Comprehensivecleaning, Cleaningandhousekeeping, Upto70squaremeters, t70to100, t100to180, Above180squaremeters, morethanonesinamonth, onesinamonth, t4timesamonthormore, Lessthan4timesamonth]);

  const estimatedServiceTimes = useMemo(() => ({
    [Comprehensivecleaning]: 6,
    [Cleaningandhousekeeping]: 4,
  }), [Comprehensivecleaning, Cleaningandhousekeeping]);

  const [selectedServices, setSelectedServices] = useState([]);
  const [area, setArea] = useState(Upto70squaremeters);
  const [serviceType, setServiceType] = useState(Comprehensivecleaning);
  const [frequency, setFrequency] = useState(morethanonesinamonth);
  const [bathroomCount, setBathroomCount] = useState(1);
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const [estimatedTime, setEstimatedTime] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);

  const calculatePriceAndTime = useCallback(() => {
    let totalMinutes = 0;
    let totalPrice = 0;

    selectedServices.forEach((service) => {
      totalMinutes += service.time;
      totalPrice += service.price;
    });

    totalPrice += (bathroomCount - 1) * 50;

    const basePrice = priceMatrix[serviceType]?.[area]?.[frequency] ?? 0;
    totalPrice += basePrice;

    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    const formattedTime = totalHours + (remainingMinutes ? 0.5 : 0);

    setEstimatedTime((estimatedServiceTimes[serviceType] || 0) + formattedTime);
    setEstimatedPrice(totalPrice);
  }, [selectedServices, area, serviceType, frequency, bathroomCount, priceMatrix, estimatedServiceTimes]);

  useEffect(() => {
    calculatePriceAndTime();
  }, [selectedServices, area, serviceType, frequency, bathroomCount, calculatePriceAndTime]);

  useEffect(() => {
    if (serviceType === Comprehensivecleaning) {
      setFrequency(morethanonesinamonth);
    } else if (serviceType === Cleaningandhousekeeping) {
      setFrequency(t4timesamonthormore);
    }
  }, [serviceType, Comprehensivecleaning, Cleaningandhousekeeping, morethanonesinamonth, t4timesamonthormore]);

  useEffect(() => {
    const closeDropdowns = (event) => {
      const dropdowns = document.querySelectorAll('.dropdown');
      dropdowns.forEach(dropdown => {
        if (!dropdown.contains(event.target)) {
          dropdown.classList.remove('active');
          const menu = dropdown.querySelector('.dropdown-menu');
          if (menu) {
            menu.style.display = 'none';
          }
        }
      });
    };

    document.addEventListener('click', closeDropdowns);

    return () => {
      document.removeEventListener('click', closeDropdowns);
    };
  }, []);

  const handleServiceChange = (event) => {
    const { checked, value } = event.target;
    const service = services.find((s) => s.name === value);

    if (checked) {
      setSelectedServices([...selectedServices, service]);
    } else {
      setSelectedServices(selectedServices.filter((s) => s.name !== value));
    }
  };

  const handleBathroomCountChange = (change) => {
    setBathroomCount(Math.max(1, bathroomCount + change));
  };

  const handleDropdownClick = (e) => {
    e.stopPropagation();
    const dropdown = e.currentTarget;
    dropdown.classList.toggle('active');
    const menu = dropdown.querySelector('.dropdown-menu');
    menu.style.display = dropdown.classList.contains('active') ? 'block' : 'none';
  };

  const handleOptionClick = (e, setter) => {
    e.stopPropagation();
    const option = e.currentTarget;
    setter(option.id);
    const dropdown = option.closest('.dropdown');
    dropdown.querySelector('.select span').textContent = option.textContent;
    
    dropdown.classList.remove('active');
    const menu = dropdown.querySelector('.dropdown-menu');
    menu.style.display = 'none';
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const handleModalSubmit = (details) => console.log('Submitted details:', details);

  const selectedDetails = {
    selectedServices,
    area,
    serviceType,
    frequency,
    bathroomCount,
    estimatedPrice,
    estimatedTime
  };

  return (
    <div className="container">
      <div className="contentWrapper">
        <div className="side2">
          <h2>{languageData.hero.tosfot}</h2>
          <div className="services">
            {services.map((service) => (
              <label key={service.name} className="serviceLabel">
                <input
                  type="checkbox"
                  value={service.name}
                  onChange={handleServiceChange}
                />
                <FontAwesomeIcon icon={service.icon} className="serviceIcon" /> {service.name} - {service.price} ₪
              </label>
            ))}
          </div>
        </div>
        <div className="side1">
          <div>
            <h3>{languageData.hero.SelectArea}</h3>
            <div className="dropdown" onClick={handleDropdownClick}>
              <div className="select">
                <span>{area}</span>
                <i className="fa fa-chevron-left"></i>
              </div>
              <input type="hidden" name="area" value={area} />
              <ul className="dropdown-menu">
                {[Upto70squaremeters, t70to100, t100to180, Above180squaremeters].map((areaOption) => (
                  <li key={areaOption} id={areaOption} onClick={(e) => handleOptionClick(e, setArea)}>
                    {areaOption}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="dropdownMenus">
            <h3>{languageData.hero.ServiceType}</h3>
            <div className="dropdown" onClick={handleDropdownClick}>
              <div className="select">
                <span>{serviceType}</span>
                <i className="fa fa-chevron-left"></i>
              </div>
              <input type="hidden" name="serviceType" value={serviceType} />
              <ul className="dropdown-menu">
                <li id={Comprehensivecleaning} onClick={(e) => handleOptionClick(e, setServiceType)}>{Comprehensivecleaning}</li>
                <li id={Cleaningandhousekeeping} onClick={(e) => handleOptionClick(e, setServiceType)}>{Cleaningandhousekeeping}</li>
              </ul>
            </div>
            <div>
              <h3>{languageData.hero.Frequency}</h3>
              <div className="dropdown" onClick={handleDropdownClick}>
                <div className="select">
                  <span>{frequency}</span>
                  <i className="fa fa-chevron-left"></i>
                </div>
                <input type="hidden" name="frequency" value={frequency} />
                <ul className="dropdown-menu">
                  {serviceType === Comprehensivecleaning ? (
                    <>
                      <li id={morethanonesinamonth} onClick={(e) => handleOptionClick(e, setFrequency)}>{morethanonesinamonth}</li>
                      <li id={onesinamonth} onClick={(e) => handleOptionClick(e, setFrequency)}>{onesinamonth}</li>
                    </>
                  ) : (
                    <>
                      <li id={t4timesamonthormore} onClick={(e) => handleOptionClick(e, setFrequency)}>{t4timesamonthormore}</li>
                      <li id={Lessthan4timesamonth} onClick={(e) => handleOptionClick(e, setFrequency)}>{Lessthan4timesamonth}</li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="bathrooms">
            <h3>{languageData.hero.Numberofbathrooms}</h3>
            <button className="bathroomButton" onClick={() => handleBathroomCountChange(-1)}>-</button>
            <span className='bathCount'>{bathroomCount}</span>
            <button className="bathroomButton" onClick={() => handleBathroomCountChange(1)}>+</button>
          </div>
          <div className="priceEstimate">
            <h2>{languageData.hero.Estimatedpricestartingfrom} <span>{estimatedPrice}</span> ₪</h2>
            <h3>{languageData.hero.Estimatedtime} <span>{estimatedTime}</span> שעות</h3>
          </div>
          <button
            className="submitButton"
            onMouseEnter={(e) => (e.target.style.backgroundColor = '#7F65C1')}
            onMouseLeave={(e) => (e.target.style.backgroundColor = 'orange')}
            onClick={handleOpenModal}
          >
            {languageData.hero.Senddetailsbtn}
          </button>
        </div>
      </div>
      <Modal 
        isOpen={isModalOpen} 
        onClose={handleCloseModal} 
        onSubmit={handleModalSubmit} 
        selectedDetails={selectedDetails}
      />
    </div>
  );
}

export default QuoteForm;